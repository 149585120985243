import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid, makeStyles, withStyles, Slider, Typography } from '@material-ui/core/';
import { select } from 'store/toolkit';

import { SERVICE_COST_INFO } from 'utils/constants';
import useContactCardContext from 'hooks/useContactCardContext';
import useProviderCost from 'hooks/Provider/useProviderCost';

import ProfileSectionTitle from '../Profile/ProfileSectionTitle';
import OutOfPocketMaximumSwitch from './OutOfPocketMaximumSwitch';
import ServiceCostTable from './ServiceCostTable';
import Accordion from '../Profile/Accordion';

const useStyles = makeStyles((theme) => ({
  serviceCostContainer: {
    marginTop: 50,
    marginBottom: 10,
  },
  dataWrapper: {
    marginTop: theme.spacing(1),
  },
  switchWrapper: {
    paddingBottom: 16,
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius * 3,
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[400]}`,
    margin: '8px 0px',
    pointerEvents: 'none',
  },
  sliderContainer: {
    width: '96%',
  },
  infoContainer: {
    marginBottom: 26,
  },
  info: {
    fontSize: 16,
  },
  bold: {
    fontWeight: 'bold',
  },
}));

const EmboldSlider = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    height: 8,
    '& :nth-child(6)': {
      height: 0,
      width: 0,
    },
    '& :nth-child(7)': {
      left: '95% !important',
    },
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: theme.palette.primary.main,
    border: '2px solid currentColor',
    marginTop: -6,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: -40,
    color: 'transparent',
    top: -20,
    '& > span': {
      borderRadius: 0,
      color: 'transparent',
      transform: 'initial',
      width: 90,
      '& > span': {
        color: theme.palette.text.primary,
        transform: 'initial',
        fontSize: 14,
      },
    },
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
  mark: {
    height: 20,
    width: 1,
    bottom: 7,
    borderRadius: theme.shape.borderRadius * 3,
    backgroundColor: theme.palette.primary.main,
  },
  markLabel: {
    color: 'rgba(0, 0, 0, 0.87)',
    width: 120,
    whiteSpace: 'initial',
    textAlign: 'center',
  },
}))(Slider);

const LOCATION_COST_DEBOUNCE_DELAY = 1000;
const ACCUMULATOR_MARKS = [
  {
    value: 5000,
    label: (
      <div>
        <div>Deductible:</div>
        <span style={{ fontWeight: 'bold' }}>$5,000</span>
      </div>
    ),
  },
  {
    value: 10000,
    label: (
      <div>
        Out-of-pocket maximum: <span style={{ fontWeight: 'bold' }}>$10,000</span>
      </div>
    ),
  },
];

function getLabel(value) {
  return (
    <div>
      Met: <span style={{ fontWeight: 'bold' }}>${value.toLocaleString()}</span>
    </div>
  );
}

export default function ServiceCostWrapper({ npi, isAccordion }) {
  const classes = useStyles();

  const enableServiceCost = useSelector(select.featureFlags.showServiceCost);
  const [isOpen, setIsOpen] = useState(false);

  // get current location data from the parent contact card
  const { currentLocation, index } = useContactCardContext();
  const { locationId } = currentLocation;
  const { fetchProviderCost } = useProviderCost(npi, currentLocation);

  /**
   * Debounce provider-cost network call:
   * - delay call to 'provider-cost' endpoint to prevent excessive network calls
   * - will not perform fetch until user has viewed location for 1000ms
   */
  const [isDebouncing, setIsDebouncing] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const createFetchTimeout = useCallback(() => {
    setIsDebouncing(true);
    const id = setTimeout(() => {
      setIsDebouncing(false);
      fetchProviderCost();
    }, LOCATION_COST_DEBOUNCE_DELAY);
    setTimeoutId(id);
  }, [fetchProviderCost, setTimeoutId]);

  const clearTimers = useCallback(() => {
    setTimeoutId(null);
    clearTimeout(timeoutId);
  }, [timeoutId]);

  /**
   * Non-Accordion UI
   * - Auto-fetch function is called when `locationId` changes
   * - Fetch & display first location immediately
   * - Debounce fetch & display for any other location
   * */
  const isInitialLocation = useMemo(() => index === 0, [index]);
  const triggerAutoFetch = useCallback(() => {
    if (isInitialLocation) {
      fetchProviderCost();
    } else {
      createFetchTimeout();
    }
  }, [createFetchTimeout, fetchProviderCost, isInitialLocation]);

  /**
   * Accordion UI
   * - Auto-fetch function is called when `locationId` changes
   * - If accordion is open, trigger a debounced fetch call
   *
   * - Fetch data with no delay when user opens accordion (handleClick)
   * */
  const triggerAutoFetchAccordion = useCallback(() => {
    if (isOpen) createFetchTimeout();
  }, [createFetchTimeout, isOpen]);

  const handleClick = useCallback(() => {
    if (!isOpen) fetchProviderCost();
    setIsOpen((prev) => !prev);
  }, [fetchProviderCost, isOpen]);

  const AccordionProps = useMemo(
    () => ({
      ariaId: 'service-costs',
      iconOnlyTrigger: true,
      plainTextTitle: 'service cost at this location',
      isOpen,
      onClick: handleClick,
      CollapseProps: { unmountOnExit: true },
    }),
    [handleClick, isOpen]
  );

  // Setup debounce functions
  const autoFetchFunction = useMemo(
    () => (isAccordion ? triggerAutoFetchAccordion : triggerAutoFetch),
    [isAccordion, triggerAutoFetch, triggerAutoFetchAccordion]
  );

  // Clear active timer and re-execute debounce function on location change
  useEffect(() => {
    clearTimers();
    autoFetchFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  // Clear active timer on component unmount
  useEffect(() => () => clearTimeout(timeoutId), [timeoutId]);

  if (!enableServiceCost) return null;
  if (!locationId) return null;

  return (
    <Grid container className={classes.serviceCostContainer} direction="row">
      <AccordionWrapper
        TitleProps={{
          title: SERVICE_COST_INFO.title,
          icon: <SERVICE_COST_INFO.IconComponent />,
          TooltipProps: {
            message: SERVICE_COST_INFO.description,
            title: SERVICE_COST_INFO.title,
          },
        }}
        isAccordion={isAccordion}
        AccordionProps={AccordionProps}
      >
        <Grid container classes={{ root: classes.dataWrapper }} item justifyContent="flex-start">
          <Grid container item classes={{ root: classes.switchWrapper }}>
            {/* <OutOfPocketMaximumSwitch /> */}
            <Grid item className={classes.sliderContainer}>
              <Grid className={classes.infoContainer}>
                <Typography className={classes.info}>
                  Deductible remaining: <span className={classes.bold}>$1,200</span>
                </Typography>
                <Typography className={classes.info}>
                  Out-of-pocket maximum remaining: <span className={classes.bold}>$6,200</span>
                </Typography>
              </Grid>
              <EmboldSlider
                aria-label="accumulation-slider"
                defaultValue={3800}
                valueLabelDisplay="on"
                color="primary"
                min={0}
                max={10000}
                marks={ACCUMULATOR_MARKS}
                valueLabelFormat={getLabel}
              />
            </Grid>
          </Grid>
          <ServiceCostTable
            analyticsView={isAccordion ? 'Accordion' : 'Modal'}
            npi={npi}
            location={currentLocation}
            isDebouncing={isDebouncing}
          />
        </Grid>
      </AccordionWrapper>
    </Grid>
  );
}

ServiceCostWrapper.propTypes = {
  npi: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isAccordion: PropTypes.bool,
};

ServiceCostWrapper.defaultProps = {
  isAccordion: false,
};

function AccordionWrapper({ isAccordion, AccordionProps, TitleProps, children }) {
  return isAccordion ? (
    <Accordion
      title={<ProfileSectionTitle {...TitleProps} icon={null} />}
      icon={TitleProps.icon}
      {...AccordionProps}
    >
      {children}
    </Accordion>
  ) : (
    <>
      <ProfileSectionTitle {...TitleProps} />
      {children}
    </>
  );
}

AccordionWrapper.propTypes = {
  isAccordion: PropTypes.bool.isRequired,
  AccordionProps: PropTypes.shape({
    ariaId: PropTypes.string,
    iconOnlyTrigger: PropTypes.bool,
    plainTextTitle: PropTypes.string,
    isOpen: PropTypes.bool,
    onClick: PropTypes.func,
  }).isRequired,
  TitleProps: PropTypes.shape({
    title: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    TooltipProps: PropTypes.shape({
      message: PropTypes.string,
      title: PropTypes.string,
    }),
  }).isRequired,
  children: PropTypes.node.isRequired,
};
